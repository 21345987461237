import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { NavLink, useParams, useNavigate} from 'react-router-dom';

import TopHeader from '../TopHeader';
import '../../Blogs/Quote/quoteonepage.css'

const SinglePostPoem = () => {
  const [getuserdata, setUserdata] = useState([]);
    console.log(getuserdata);

    const { id } = useParams("");
    console.log(id);

    const history = useNavigate();


    const getdata = async () => {

        // const res = await fetch(`https://blog-ak-server.herokuapp.com/getuser/${id}`, {
        const res = await fetch(`https://akblogs-server.vercel.app/getuser/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();
        console.log(data);

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setUserdata(data)
            console.log("get data");
        }
    }

    useEffect(() => {
        getdata();
    }, [])

    return (
        <>
       <div className ="Quote-body">
        <div style={{display:"none"}}> <TopHeader/></div>
        <div className="Quote-cards"> 
    
    <div className="Quote-card">
    <p style={{textAlign: "right"}}><Link to="/">Home</Link></p>
    <h1 className="Quote-h1">{getuserdata.title}</h1>
  
      <p style={{whiteSpace: "pre-wrap"}}> <center>{getuserdata.desc}</center>  </p>
    <p style={{textAlign:"right"}}>-{getuserdata.authorname}</p>
  
    </div>
  </div>
  </div>
        </>
    )
}

export default SinglePostPoem;