



const Aboutme = () => {

    return (
        <>
		<embed src="https://ajaykrishnan.vercel.app/" style={{width:"100%", height:"100vh"}}></embed>
      
        </>
    )
}

export default Aboutme;