import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { NavLink, useParams, useNavigate} from 'react-router-dom';
import CoverImg from '../../Images/Logo/AKBlogsLogo5.png'

import TopHeader from '../TopHeader';
import './singlepost.css'

const SinglePostStory = () => {
  const [getuserdata, setUserdata] = useState([]);
    console.log(getuserdata);

    const { id } = useParams("");
    console.log(id);

    const history = useNavigate();


    const getdata = async () => {

        // const res = await fetch(`https://blog-ak-server.herokuapp.com/getuser/${id}`, {
        const res = await fetch(`https://akblogs-server.vercel.app/getuser/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();
        console.log(data);

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setUserdata(data)
            console.log("get data");
        }
    }

    useEffect(() => {
        getdata();
    }, [])

    const string = getuserdata.desc
    // const first_str = string.charAt(0)
    // const rem_string = string.substring(1)
    return (
        <>
        <div style={{display:"none"}}> <TopHeader/></div>
       
        <div className="singlepost-main">
  <section className="lp"> 
    <div className="col">
      <nav className="singlepost-nav">
        <p className="a-link">{getuserdata.subcategory}</p>
        <a style={{textDecoration:"none"}}><Link to="/" className="a-link" style={{textDecoration:"none"}}>Home</Link> </a>
        {/* <a href="#" className="nav__link">Link 2</a> */}
        {/* <a href="#" className="nav__link">Link 3</a> */}
      </nav>
      <div className="blog__content">
      <h1 className="singlepost-h1">{getuserdata.title} </h1>

      
      <div className="blog__mainText">
    {/* <p><span className="blog__mainText__letter">{first_str}</span>        
              {rem_string}
         </p> */}
        <p style={{whiteSpace: "pre-wrap"}}>{string}  </p>
         </div>
        </div>
    </div>
    
    <div className="col">
    <img src="https://images.unsplash.com/photo-1583864442126-cf72052ced87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" className="blog__img" />
    {/* <img src={CoverImg} className="blog__img" /> */}
    </div>
  </section>
</div>
        </>
    )
}

export default SinglePostStory;