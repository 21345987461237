
import EnglishPoems from '../../Blogs/EnglishPoems';
import EnglishStories from '../../Blogs/EnglishStories';
import Entertainment from '../../Blogs/Entertainmant';
import Posts from '../../Blogs/Posts'
import TamilPoems from '../../Blogs/TamilPoems';
import TamilStories from '../../Blogs/TamilStories';
import Technical from '../../Blogs/Technical';
import AllBlogs from '../../Blogs/AllBlogs';
import './Tab.css'
import Quotes from '../../Blogs/Quotes';
// import Photography from '../../Blogs/Photography/Photography'
import Photography from '../../Blogs/Photography/PhotographyNew'
import Youtube from '../../Blogs/Youtube/Youtube';
import Aboutme from '../../Blogs/Aboutme';

const Tabs = () => {
   
// தமிழ் கவிதைகள் | Tamil Poems    தமிழ் கதைகள் | Tamil kathaigal   English Poems   Engish Stories  Entertainment   Technical
    return (
        <>
        <section id="tab-page" >
        <section class="tabs-container">
           <div className="tab-cate"><p className="tab-cate-para">Categories ︾</p></div> 
           
   <label for="default"> All Blogs </label>
   <label for="tab7">Quotes </label> 
   <label for="tab1"> தமிழ் கவிதைகள் | Tamil Kavithaigal </label>
   <label for="tab2"> தமிழ் கதைகள் | Tamil Kathaigal </label>
   <label for="tab3"> English Poems</label>
   <label for="tab4"> Engish Stories </label>
   <label for="tab9">Youtube </label>
   <label for="tab5"> Entertainment</label>
   <label for="tab8">PhotoGraphy </label>
   <label for="tab6">Technical </label>
   <label for="tab10">About me </label>

   {/* <label for="tab10"> Name </label> */}
  
</section>


<input name="tab" id="tab1" type="radio" checked />
<section class="tab-content">
<TamilPoems />
</section>
<input name="tab" id="tab2" type="radio" checked />
<section class="tab-content">
<TamilStories />
</section>
<input name="tab" id="tab3" type="radio" checked />
<section class="tab-content">
<EnglishPoems />
</section>
<input name="tab" id="tab4" type="radio" checked />
<section class="tab-content">
<EnglishStories />
</section>
<input name="tab" id="tab5" type="radio" checked />
<section class="tab-content">
<Entertainment />
</section>
<input name="tab" id="tab6" type="radio" checked />
<section class="tab-content">
<Technical />
</section>
<input name="tab" id="tab7" type="radio" checked />
<section class="tab-content">
<Quotes />
</section>
<input name="tab" id="tab8" type="radio" checked />
<section class="tab-content">
   <Photography />
</section>
<input name="tab" id="tab9" type="radio" checked />
<section class="tab-content">
   <Youtube />
</section>
<input name="tab" id="tab10" type="radio" checked />
<section class="tab-content">
   <Aboutme />
</section>

<input name="tab" id="default" type="radio" checked />
<section class="tab-content">
   <AllBlogs />
</section>
</section>
        </>
    )
}

export default Tabs;