import React, { useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom';

import {adddata, deldata} from '../Components/context/ContextProvider'
import {updatedata} from '../Components/context/ContextProvider'



const Quote = () => {
    const [getuserdata, setUserdata] = useState([]);
    console.log(getuserdata);

    const { udata, setUdata } = useContext(adddata);

    const {updata, setUPdata} = useContext(updatedata);

    // const {dltdata, setDLTdata} = useContext(deldata);


    const getdata = async () => {

        // const res = await fetch("https://blog-ak-server.herokuapp.com/getdata", {  
        // const res = await fetch("https://blog-ak-server.herokuapp.com/getquotes/Quote", {
        const res = await fetch("https://akblogs-server.vercel.app/getquotes/Quote", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();
        console.log(data);

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setUserdata(data)
            console.log("get data");

        }
    }

    useEffect(() => {
        getdata();
    }, [])
    return (
        <> 
        {/* <h3>Quote of the Day</h3> */}
        {
            getuserdata.map((element, id) => {
                return (
                    <>
                    <p>❝{element.desc}❞</p>
                    
                    </>
                )
            })
        }
       
        
    </>
    )
}

export default Quote;