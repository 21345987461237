

import TypeWriter from './TypeWriteTexct'

// import Quote from '../Blogs/Quote'



const ChangingText = () => {
const staticText = [`HEY!! ,`]
// const words = ['If You are reading this, it means you have made it to this fine day. Collect beautiful moments and be in love with the places and people you have met. ']
const words = ['', 'VANAKAM', 'HELLO', 'VANTHANAM', 'NAMASTHE', 'BONJOUR', 'HOLA', 'KON’NICHIWA', 'ANYEONG HASEYO', 'ASSALAM U ALAIKUM']



    return (
        <>
                         <h2>
                         <TypeWriter staticText={staticText} words={words}/>
                         </h2>
        
        </>
    )
}

export default ChangingText;