import React, {Component} from 'react'
import Navigation from '../Route/Navigation';
import Home from './Home';
import '../App.css'
import '../Styles/LoadingScreen.css'
import TopHeader from './TopHeader';

const Loading =()=>
  <div className="loadingscreenloading">
    <div><h1>AK</h1></div>
    <div><h1>Blogs</h1></div>
  </div>  



class LoadingScreen extends Component {
  state = {
    loading: true,
  };
  
  componentDidMount(){
    this.isLoading = setTimeout(()=>{this.setState({loading: false})}, 2300);
  }
  componentWillUnmount() {
     clearTimeout(this.isLoading);
  }
  
  timer = () => setTimeout(()=>{
    this.setState({loading: false})
  }, 2300);

  render() {
    const {loading} = this.state;
    return (
      loading ? (
          <div className="loadingscreenbody">
      <Loading/>
      </div>
      )
      :(<div className="ak">
      <div style={{position:"sticky", top:"0", zIndex:"10"}}>
      <TopHeader/>
      </div>
      <Navigation />

    </div>
    )
    )
  }
}

export default LoadingScreen;