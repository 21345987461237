import { Routes, Route } from 'react-router-dom';
import EnglishPoems from '../Blogs/EnglishPoems';
import EnglishStories from '../Blogs/EnglishStories';
import LifeStory from '../Blogs/EnglishStories/LifeStory';
import QuoteOnePage from '../Blogs/Quote/QuoteOnepage';
import Quotes from '../Blogs/Quotes';
import AllBlogs from '../Blogs/AllBlogs';
import TamilPoems from '../Blogs/TamilPoems';
import TamilStories from '../Blogs/TamilStories';
import AllStories from '../Blogs/TamilStories/AllStories';
import Part1 from '../Blogs/TamilStories/Part1';
import ShortStories from '../Blogs/TamilStories/ShortStories';
import Technical from '../Blogs/Technical';

import Home from '../Components/Home';
import SinglePost from '../Components/SinglePost/SinglePost';
import SinglePostPoem from '../Components/SinglePost/SinglePostPoem';
import SinglePostStory from '../Components/SinglePost/SinglePostStory';
// import Tabs from '../Components/Tabs/Tabs';
import Write from '../Components/Write/Write';
import Entertainment from '../Blogs/Entertainmant';
import PhotographyNew from '../Blogs/Photography/PhotographyNew';
import Youtube from '../Blogs/Youtube/Youtube';
// import TopHeader from '../Components/TopHeader';


const Navigation = () => { 
    return(
        
        <Routes>
            {/* Basic Routes */}
            <Route path = "/" element = {<Home />} />
            <Route path="/write" element = {<Write />} />

            <Route path="/Tamil%20Kavithai" element = {<TamilPoems />} />
            <Route path="/Tamil%20Kathai" element = {<TamilStories />} />
            <Route path="/English%20Poems" element = {<EnglishPoems />} />
            <Route path="/English%20Stories" element = {<EnglishStories />} />
            <Route path="/Technical" element = {<Technical />} />
            <Route path="/Quote" element = {<Quotes />} />
            {/* All Blogs Route */}
            <Route path="/allblogs" element = {<AllBlogs />} />
            <Route path="/tamilkavithai" element = {<TamilPoems />} />
            <Route path="/tamilkathai" element = {<TamilStories />} />
            <Route path="/tamilkathai/kannampoochikaathal" element = {<Part1 />} />
            <Route path="/tamilkathai/sirukathaigal" element = {<ShortStories />} />
            <Route path="/englishpoems" element = {<EnglishPoems />} />
            <Route path="/englishstories" element = {<EnglishStories />} />
            <Route path="/technical" element = {<Technical />} />
            <Route path="/quotes" element = {<Quotes />} />
            <Route path="/entertainment" element = {<Entertainment />} />
            <Route path="/photography" element = {<PhotographyNew />} />
            <Route path="/youtube" element = {<Youtube />} />
            {/* <Route path="/tabs" element = {<Tabs />} /> */}
            {/* Single Post View */}
            <Route path="/viewpoem/:id" element = {<SinglePostPoem />} />
            <Route path="/viewstory/:id" element = {<SinglePostStory />} />
            <Route path="/viewquote/:id" element = {<QuoteOnePage />} />

            <Route path="/view/English%20Stories/:id" element = {<SinglePostStory />} />
            <Route path="/view/Tamil%20Kathai/:id" element = {<SinglePostStory />} />
            <Route path="/view/Tamil%20Kavithai/:id" element = {<SinglePostPoem />} />
            <Route path="/view/English%20poems/:id" element = {<SinglePostPoem />} />
            <Route path="/view/Quote/:id" element = {<QuoteOnePage />} />
            <Route path="/view/Technical/:id" element = {<SinglePostStory />} />

            <Route path="/viewstory/lifestory" element = {<LifeStory />} />
 
        </Routes>
    )
    
}


export default Navigation;