import React, { useContext, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { adddata } from '../context/ContextProvider';
// import TopHeader from '../TopHeader';

import './Write.css'


const Write = () => {
    const { udata, setUdata } = useContext(adddata);

    const history = useNavigate();

    const [inpval, setINP] = useState({
        title: "",
        authorname: "",
        email: "",
        category: "",
        subcategory: "",
        desc: "",
        createDate: new Date().toLocaleString(),
    })

    const setdata = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setINP((preval) => {
            return {
                ...preval,
                [name]: value
            }
        })
    }


    const addinpdata = async (e) => {
        e.preventDefault();

        const { title,authorname,email,category,subcategory,desc,createDate } = inpval;
        
        // const res = await fetch("https://blog-ak-server.herokuapp.com/register", {
        const res = await fetch("https://akblogs-server.vercel.app/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title,authorname,email,category,subcategory,desc,createDate
            })
        });

        const data = await res.json();
        console.log(data);

        if (res.status === 422 || !data) {
            console.log("error ");
            alert("Title Already exist. Choose Different Title or If you left any required space blank fill it");

        } else {
            window.location.replace("/")
            setUdata(data)
            console.log("data added");

        }
    }
    return (
        <>
      <div className="form-max-width-1 form-m-auto">
          {/* <TopHeader /> */}
            <div className="">
               
            <form className="bp4-form-group form-write-form ">
                <div className="bp4-form-content ">
                    <div className="form-row" >
                        <div className="form-home-article form-more-post"> 
                            <label for="exampleInputEmail1" className="form-form-label bp4-label">Title<span className="required">*</span> 
                            <input className="bp4-input bp4-fill   bp4-large form-input-text"  type="text" placeholder="Title of the Blog"  value={inpval.title} onChange={setdata} name="title" id="exampleInputEmail1" aria-describedby="emailHelp" dir="auto" />
                            </label>
                        </div>
                        <div className="form-home-article form-more-post"> 
                            <label for="exampleInputPassword1" className="form-form-label bp4-label">Date<span style={{visibility: "hidden"}} className="required">*</span>
                            <input className="bp4-input bp4-fill  bp4-large form-input-text "  type=""  value={inpval.createDate}  name="createDate" id="exampleInputPassword1" dir="auto" />
                            </label>
                        </div>
                    </div>
                    <div className="form-row" >
                        <div className="form-home-article form-more-post"> 
                            <label for="exampleInputPassword1" className="form-form-label bp4-label">Author<span className="required">*</span>
                            <input className="bp4-input bp4-fill   bp4-large form-input-text"  type="text" placeholder="Author Name"  value={inpval.authorname} onChange={setdata} name="authorname" id="exampleInputPassword1" dir="auto" />
                            </label>
                        </div>
                        <div className="form-home-article form-more-post"> 
                            <label for="exampleInputPassword1" className="form-form-label bp4-label">Email<span className="required">*</span>
                            <input className="bp4-input bp4-fill  bp4-large form-input-text"  type="email" placeholder="Your Email ID"  value={inpval.email} onChange={setdata} name="email" id="exampleInputPassword1" dir="auto" />
                            </label>
                        </div>
                    </div>
                    {/* Radio Buttons */}
                    <div className="form-row">
                    
                        <div className="form-radio-button ">
                                                
                        <label for="exampleInputPassword1" className="form-form-label bp4-label">Category<span className="required">*</span></label>
                            <div>
                                <label class="bp4-control form-form-label bp4-label bp4-radio .modifier">
                                <input type="radio" value="Tamil Kavithai" onChange={setdata} name="category"  id="exampleInputPassword1"  />
                                <span class="bp4-control-indicator"></span>
                                Tamil Kavithai
                                </label>
                            </div>
                            <div>
                                <label class="bp4-control form-form-label bp4-label bp4-radio .modifier">
                                <input type="radio" value="Tamil Kathai" onChange={setdata} name="category"  id="exampleInputPassword1" />
                                <span class="bp4-control-indicator"></span>
                                Tamil Kathai
                                </label>
                            </div>                        
                            <div>
                                <label class="bp4-control form-form-label bp4-label bp4-radio .modifier">
                                <input type="radio" value="English Poems" onChange={setdata} name="category"  id="exampleInputPassword1" />
                                <span class="bp4-control-indicator"></span>
                                English Poems
                                </label>
                            </div>
                            <div>
                                <label class="bp4-control form-form-label bp4-label bp4-radio .modifier">
                                <input type="radio" value="English Stories" onChange={setdata} name="category"  id="exampleInputPassword1" />
                                <span class="bp4-control-indicator"></span>
                                English Stories
                                </label>
                            </div>
                            <div>
                                <label class="bp4-control form-form-label bp4-label bp4-radio .modifier">
                                <input type="radio" value="Technical" onChange={setdata} name="category"  id="exampleInputPassword1" />
                                <span class="bp4-control-indicator"></span>
                                Technical
                                </label>
                            </div>
                            <div className="dummy">
                            <label  className="form-form-label bp4-label">Sub Category
                            <input className="bp4-input bp4-fill   bp4-large form-input-text"  type="text" placeholder="Sub Category" dir="auto" />
                            </label>
                            </div>
                            
                        </div>
                        <div className="form-home-article form-more-post"> 
                            <label for="exampleInputPassword1" className="form-form-label bp4-label">Sub Category<span style={{visibility: "hidden"}} className="required">*</span>
                            <input className="bp4-input bp4-fill   bp4-large form-input-text"  type="text" placeholder="Sub Category"  value={inpval.subcategory} onChange={setdata} name="subcategory" id="exampleInputPassword1" dir="auto" />
                            </label>
                        </div>
                    </div>
                    <div>
                    <label for="exampleInputPassword1" className="form-form-label bp4-label">Description<span className="required">*</span>
                    <pre>
                    <textarea name="desc" value={inpval.desc} onChange={setdata} className="bp4-input bp4-large bp4-fill form-input-text" id="" cols="30" rows="15" dir="auto" />
                    </pre>
                            </label>
                    </div>

                    <div className="center" style={{paddingTop: "10px", paddingBottom: "50px"}}>
                    <button type="submit" className="form-form-button " onClick={addinpdata}>Submit</button>
                    </div>
                    {/* bp4-button bp4-fill */}
                </div>
            </form>
            
            </div>
        </div>
        </>
    )
}

export default Write;