
import {useEffect} from 'react';
import '../../Styles/BlogPosts.css'
import { Link } from 'react-router-dom'; 

import './Youtube.css'

const Youtube = () => {
    const loadVideo = (iframe) => { 
        const cid = "UCC3KVP2eXXb45wuzdAybWxg"
        const channelURL = encodeURIComponent(`https://www.youtube.com/feeds/videos.xml?channel_id=${cid}`)
        const reqURL = `https://api.rss2json.com/v1/api.json?rss_url=${channelURL}`
        fetch(reqURL)
        .then(response => response.json())
        .then(result => { 
            const videoNumber = iframe.getAttribute('vnum')
        const link = result.items[videoNumber].link;
        const id = link.substring(link.indexOf("=") + 1 );
        iframe.setAttribute("src", `https://youtube.com/embed/${id}?controls=0&autoplay=1`);
         })
        // console.log(result)
        
    }

    useEffect(() => {
        const iframes = Array.from(
            document.getElementsByClassName('latestVideoEmbed')
        );
        console.log(iframes);
        for (let i = 0, len = iframes.length; i < len; i++) {
            loadVideo(iframes[i]);
          }
      }, []);
        // .catch(error => console.log('error', error));
             

        
      
        const videos = [ ]
        
        for (var i = 0; i < 4; i++) {
                videos.push(i)
        }
        const listItems = videos.map((number) => {
            return(
        <div style={{
            // display: "flex",
            justifycontent: "center",
            position: "relative",
            overflow: "hidden",
            width: "100%",
            paddingTop: "56.25%",
  
        }}>
            <iframe className="latestVideoEmbed" style={{
                position:"absolute",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                maxWidth: "100%",
                height: "100%",
                padding: "1rem"

            }} vnum={number} frameborder="0" allowfullscreen></iframe>
            </div>
       ) });
            
    
    
    return (
        <>
        <div className="main">
        <Link to="/youtube"><h1 className="headingh1">Youtube</h1></Link>
        
        <div className="ytbtn-1">
        <script src="https://apis.google.com/js/platform.js"></script>
        <a href="https://www.youtube.com/channel/UCC3KVP2eXXb45wuzdAybWxg?view_as=subscriber" target="_blank" className="ytbutton ytbutton-blue">Click Here for More Videos</a>
        
         </div>
        
        {/* <div className="g-ytsubscribe" data-channelid="UCC3KVP2eXXb45wuzdAybWxg" data-layout="full" data-theme="dark" data-count="hidden"></div> */}
        
            <div className="videos">
                {listItems}
                </div>
            
            
        </div>
        
        </>
    )
}

export default Youtube;