import '../Styles/TamilStories.css'
import AllStories from './TamilStories/AllStories';
import Part1 from './TamilStories/Part1';
// import Part2 from './TamilStories/Part2';
import ShortStories from './TamilStories/ShortStories';
// const { default: Posts } = require("./Posts")


const TamilStories = () => {
    return (
        <>
            <div class="story-tabs">
            <label for="story-tab1">கதைகள்</label>
            <label for="story-tab2">சிறுகதைகள்</label>
            <label for="story-tab3">கண்ணாம்பூச்சி காதல்</label>


            <input type="radio" name="story-tab" id="story-tab3" checked/>
            <input type="radio" name="story-tab" id="story-tab2" checked/>
            <input type="radio" name="story-tab" id="story-tab1" checked/>
            {/* <input type="radio" name="story-tab" id="story-tab4"/>
            <label for="story-tab4">Part2</label> */}
        
            <div class="story-tab-content-wrapper">
            <div id="story-tab-content-1" class="story-tab-content" >
                
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur mattis nibh, non ornare neque. In bibendum consequat imperdiet. Duis eros ex, vestibulum vel fermentum ut, gravida at turpis. Etiam porta sem dolor, at finibus metus consequat a. Aliquam erat volutpat. Donec sollicitudin metus quis magna faucibus, vitae ultrices libero ultrices. Sed ut dui vitae velit laoreet commodo. Nam suscipit purus a ultricies auctor. </p> */}
                <AllStories />
            </div>
            <div id="story-tab-content-2" class="story-tab-content">    
                <ShortStories />
                 </div>
            <div id="story-tab-content-3" class="story-tab-content">    
                <Part1 />
                 </div>
            {/* <div id="story-tab-content-4" class="story-tab-content">  
               <Part2 />
                </div> */}
            </div>
        </div>
        </>
    )
}

export default TamilStories;