import TopHeader from '../../Components/TopHeader'

import LifeImg from '../../Images/LifeStory.jpg'
import LifeCover from '../../Images/LifeCover.jpg'
import { Link } from 'react-router-dom';

const LifeStory = () => {
    return (
        <>
        <div style={{display: "none"}}> <TopHeader/></div>
       
       <div className="singlepost-main">
 <section className="lp">
   <div className="col">
     <nav className="singlepost-nav">
       <a style={{textDecoration:"none"}}><Link to="/" className="a-link" style={{textDecoration:"none"}}>Home</Link> </a>
       {/* <a href="#" className="nav__link">Link 2</a> */}
       {/* <a href="#" className="nav__link">Link 3</a> */}
     </nav>
     <div className="blog__content">
     <h1 className="singlepost-h1">Life - Motivating Story </h1>

     
     <div className="blog__mainText">
     <img src={LifeImg} style={{width: "100%", height: "100%"}}></img>
       <p><span className="blog__mainText__letter">L</span>
       ook at the above pic. The creativity was lit. It contains two bride and groom papers and a paper stabler with lots of pins in it. 
         The pin inside the stabler helps the two papers (bride and groom) to join. Now let us think that in another way. 
         Consider one of that paper as you and the other paper was your ambition. Now the paper stabler here was your life. 
         Life contains lot of people like family, friends, love, crush, enemy, stranger and lot more people who are the pins inside the paper stabler(life). 
         The best pin will make strong bonding between the papers and the worst pin wouldn't have attach to the paper. 
         Like wise the best people around you will support you to achieve your dream and the worst will push you down. 
         Hold your dreamz and try to achieve it. The supporting people will always supports you. Don't lose your hope. 
         Achieve something in the life which speaks for the rest of the life. Don't care about what people care about you. 
         If you like doing something that makes you feel good and comfortable do that. 
         People will always murmur about you behind your back to make you feel wrong. 
         It's really waste of time and they are not going to award you if you try to prove yourself by answering them how you are right in your perspective.
          Let them talk and you keep on moving with what you like and what you love.
        </p>
       {/* <p style={{whiteSpace: "pre-wrap"}}> 
       
        </p> */}
        </div>
       </div>
   </div>
   
   <div className="col">
   <img src={LifeCover} className="blog__img" />
   </div>
 </section>
</div>
        </>
    )
}

export default LifeStory;