import Logo from '../../Images/ShortFilm/VB.jpg';

const Greenway = () => {
    return (
        <div >
            <img
                    width={64}
                    height={64}
                    className="mr-3"
                    src={Logo}
                    alt="Vals Background Films"
                />
                <h2 className="entertainment-text">Vals Background Films</h2>
                    <p className="entertainment-text">
                    Vals Background Films is a another venture in Youtube started in 2017 to upload mem videos to entertain larger audience, <br></br>
                    Initially it was started in the name Legend Creation and later it was changed to Greenway Entertainment and now its Vals Background Films. <br></br>
                    The older videos of this channel was deleted due to copyright issues and now it was used to release the short films. <br></br>
                    Two short Films with a great messages were released in this channel
                    </p>
                    {/* <div>
                    <a href="https://www.instagram.com/trendyplanetproductions/" rel="noopener noreferrer" target="_blank">
                    <button variant="" className="trendyplanetbutton">
                            <i className="fa fa-instagram" style={{fontSize:"1em"}} aria-hidden="true"> Click here to see <br></br>Our Instagram Page</i> 
                            </button>
                        </a>
                    </div> */}
                <hr />
                <br></br>
                    <h3 className="entertainment-text"><b>Arivili</b></h3> 
                    <p className="entertainment-text"><b>Language:</b> Tamil</p> 
                    <p className="entertainment-text"><b> Genre:</b> Social</p> 
                     <p className="entertainment-text"><b> Released Date:</b>Oct 20, 2021</p>
                    <div>
                        <br></br>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/X8tl-8zc8Io" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen></iframe>
                    </div>
                    <br></br>
                    <hr />
                    <br></br>
                    <h3 className="entertainment-text"><b>Agnanam</b></h3>
                    <p className="entertainment-text"><b>Language:</b> Tamil</p> 
                    <p className="entertainment-text"><b> Genre:</b>Thriller,Social</p> 
                     <p className="entertainment-text"><b> Released Date:</b>May 14, 2021</p>
                    <div>
                        <br></br>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/coy3bq5vI30" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <br></br>
                    <hr />
        </div>
    )
}

export default Greenway;