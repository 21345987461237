import React,{Component} from 'react';
import { Link, NavLink } from 'react-router-dom';
import Quote from '../../Blogs/Quote';

import '../../Styles/LandingPage.css'
import ChangingText from './ChangingText';





class LandingPage extends Component {
    constructor() {
        super()
        this.state = {
            hour: null,
            startDate: new Date()
        }
    }

    componentDidMount() {
        this.getHour()
      }
    
      getHour = () => {
       const date = new Date();
       const hour = date.getHours()
       this.setState({
          hour
       });
      }
      
    render(){
        const {hour} = this.state;
        return (
            <>
        <header className="head-main">
           
            <div class="header-text">
            
                <h1>
                    <ChangingText />
                     {/* <br /> */}
                     <span id="photography"> {
                    hour < 12 ? `GOOD MORING READER` : 
                    hour < 16 ? `GOOD AFTERNOON READER` :
                    hour < 20 ? `GOOD EVENING READER` :
                    `WELCOME READER, NIGHT TIME IS BEST FOR READING`
                    }  </span>
                     </h1>
              
                <p> 
                    {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.  */}
                <Quote />
                </p>
                 {/*  <NavLink to='/tabs'> <button id="learn"> Learn More </button></NavLink> */} 
            </div>
        </header>
        </>

        )
    }
}

export default LandingPage;