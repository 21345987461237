import '../Styles/Entertainment.css'

import { Link } from 'react-router-dom';
import '../Styles/BlogPosts.css'

import   Imag from '../Images/sample.jpg'
import   ComingSoon from '../Images/comingsoon.jpg'
import TrendyPlanet from './Entertainment/TrendyPlanet';
import Greenway from './Entertainment/Greenway';

import Logo1 from '../Images/ShortFilm/TrendyPlanet.jpg';
import Logo2 from '../Images/ShortFilm/VB.jpg';

const Entertainment = () => {

    return (
        <>
		<div className="entertainment-tabs">
        <Link to="/entertainment"><h1 className="headingh1">Entertainment</h1></Link>

<label for="entertainment-tab1">
	<i className="fa fa-html5">
	<img
        className="entertainment-responsive"
                    src={Logo1}
                    alt="Trendy Planet Productions"
                />
		</i>
		<span>Trendy Planet Productions</span>
</label>


<label for="entertainment-tab2">
	<i className="fa fa-css3">
		<img
        className="entertainment-responsive"
                    src={Logo2}
                    alt="Vals Background Films"
                />
					</i><span>Vals Background Films</span>
</label>
<input type="radio" className="entertain-input" name="entertainment-tabs" id="entertainment-tab2" checked/>
<input type="radio" className="entertain-input" name="entertainment-tabs" id="entertainment-tab1"  checked />

<div id="entertainment-tab-content1" className="entertainment-tab-content">
	<TrendyPlanet />
	</div>

<div id="entertainment-tab-content2" className="entertainment-tab-content">
	<Greenway />
</div>



</div>
      
        </>
    )
}

export default Entertainment;