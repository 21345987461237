import React, { useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom';

import {adddata, deldata} from '../../Components/context/ContextProvider'
import {updatedata} from '../../Components/context/ContextProvider'


import { Link } from 'react-router-dom';
import '../../Styles/BlogPosts.css'

import Imag from '../../Images/BlogPost/Img5.jpg'
import ComingSoon from '../../Images/comingsoon.jpg'

const ShortStories = () => {
    const [getuserdata, setUserdata] = useState([]);
    console.log(getuserdata);

    const { udata, setUdata } = useContext(adddata);

    const {updata, setUPdata} = useContext(updatedata);

    // const {dltdata, setDLTdata} = useContext(deldata);


    const getdata = async () => {

        // const res = await fetch("https://blog-ak-server.herokuapp.com/getdata", {  
        // const res = await fetch("https://blog-ak-server.herokuapp.com/getsubcategory/Short Stories", {
        const res = await fetch("https://akblogs-server.vercel.app/getsubcategory/Short Stories", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();
        console.log(data);

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setUserdata(data)
            console.log("get data");

        }
    }

    useEffect(() => {
        getdata();
    }, [])
    return (
        <>
       <div className="main"> 
			<Link to="/tamilkathai/sirukathaigal"><h1 className="headingh1">சிறுகதைகள்</h1></Link>
            <div className="post-list post-list_grid">
            {
                getuserdata.map((element, id) => {
                    return (
                        <>
            <div className="post-list__post post">
                {/*  */}
                <figure className="post__preview-img">
                    <a><Link to ={`/viewstory/${element._id}`}><img src={Imag} alt="img description" /></Link></a>
                </figure>
                
                <div className="post__header">
                    <time datetime="2019-11-16" className="post__time">{element.createDate}</time>
                    <h2 className="headingh2"><a><Link to ={`/viewstory/${element._id}`}>{element.title}</Link></a></h2>
                </div>
                
                <div className="post__meta">
                    <a><Link to="/tamilkathai">{element.category}</Link></a>
                    <a href="#" className="post__comments">{element.authorname}</a>
                </div>
                {/*  */}
            </div>
            
                        </>
                    )
                })
            }
            <div className="post-list__post post">
                    {/*  */}
					<figure className="post__preview-img">
						<a href="#"><Link to ="/view"><img src={ComingSoon} alt="img description" /></Link></a>
					</figure>
					
					<div className="post__header">
						<time datetime="2019-11-16" className="post__time">N/A</time>
						<h2 className="headingh2"><a href="#"><Link to ="/view">Coming Soon</Link></a></h2>
					</div>
					
					<div className="post__meta">
						<a href="#">Soon...</a>
						<a href="#" className="post__comments">0</a>
					</div>
                    {/*  */}
				</div>
            </div>
			
			
            </div>
        </>
    )
}

export default ShortStories;