import Logo from '../../Images/ShortFilm/TrendyPlanet.jpg';




const TrendyPlanet = () => {
    return (
        <div >
            <img
                    width={64}
                    height={64}
                    className="mr-3"
                    src={Logo}
                    alt="Trendy Planet Productions"
                />
                <h2 className="entertainment-text">Trendy Planet Productions</h2>
                    <p className="entertainment-text">
                    Trendy Planet Productions is our Youtube channel started in 2018 in which our short films and short videos are released. 
                    </p>
                    <div>
                        <br></br>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/ssYhomwpTao" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen></iframe>
                    </div>
                    {/* <div>
                    <a href="https://www.instagram.com/trendyplanetproductions/" rel="noopener noreferrer" target="_blank">
                    <button variant="" className="trendyplanetbutton">
                            <i className="fa fa-instagram" style={{fontSize:"1em"}} aria-hidden="true"> Click here to see <br></br>Our Instagram Page</i> 
                            </button>
                        </a>
                    </div> */}
                <hr />
                <br></br>
                    <h3 className="entertainment-text"><b>Aatkal Thevai</b></h3> 
                     <p className="entertainment-text"><b>Language:</b> Tamil</p> 
                    <p className="entertainment-text"><b> Genre:</b> Social, Short, Comedy</p> 
                     <p className="entertainment-text"><b> Released Date:</b> May 17, 2018</p>
                   
                    <div>
                        <br></br>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/FgGKWUSb508" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen></iframe>
                    </div>
                    <br></br>
                    <hr />
                    <br></br>
                    <h3 className="entertainment-text"><b>Stolen</b></h3>
                    <p className="entertainment-text"><b>Language:</b> Tamil</p> 
                    <p className="entertainment-text"><b> Genre:</b> Thriller, Suspense, Action, Series</p> 
                     <p className="entertainment-text"><b> Released Date:</b>  Nov 17, 2018</p>
                    <div>
                        <br></br>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/hrtMbt8_Wxs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <br></br>
                    <hr />
        </div>
    )
}

export default TrendyPlanet;