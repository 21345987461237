// import TopHeader from "./TopHeader";
import LandingPage from "./LandingPageComp/LandingPage";
import Tabs from "./Tabs/Tabs";






function Home() {
  return (
    <>
    {/* <TopHeader /> */}
    <LandingPage />
    <Tabs /> 
    
    {/* <DarkMode /> */}
    </>
  );
}

export default Home;
