import React, { useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom';

import {adddata, deldata} from '../Components/context/ContextProvider'
import {updatedata} from '../Components/context/ContextProvider'


import { Link } from 'react-router-dom';
import '../Styles/BlogPosts.css'

import LifeImg from '../Images/LifeCover.jpg'
// import   Imag from '../Images/sample.jpg'
import   ComingSoon from '../Images/comingsoon.jpg'
import   Imag from '../Images/BlogPost/Img1.jpg'
import   Youtube from '../Images/BlogPost/youtube.jpg'

import AKBlogs from '../Images/Video/AKBlogsLogo.mp4'

const EnglishStories = () => {
    const [getuserdata, setUserdata] = useState([]);
    console.log(getuserdata);

    const [getlink, setLink] = useState('');

    const { udata, setUdata } = useContext(adddata);

    const {updata, setUPdata} = useContext(updatedata);

    // const {dltdata, setDLTdata} = useContext(deldata);


    const getdata = async () => {

        // const res = await fetch("https://blog-ak-server.herokuapp.com/getdata", {  
        // const res = await fetch("https://blog-ak-server.herokuapp.com/getdata", {
        const res = await fetch("https://akblogs-server.vercel.app/getdata", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();
        console.log(data);

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setUserdata(data)
            console.log("get data");

        }
    }

    useEffect(() => {
        getdata();
    }, [])
  
    let imgtype =""
    const cat = getuserdata.category 
    console.log(cat)
    if(cat === "Tamil Kavthai"){
        imgtype = {Imag}
    }
    
    return (
        <>
       <div className="main"> 
			<Link to="/allblogs"><h1 className="headingh1">All Blogs</h1></Link>

            <div>
                <center>
                <video style={{maxWidth:"100%", height:"auto"}} width="400" controls src={AKBlogs}>
                    {/* <source src="../Images/Video/AKBlogsLogo.mp4" type="video/mp4"></source> */}
                </video>
                </center>
            </div>
            <br></br>
            <div className="post-list post-list_grid">
                {/*  */}
                <div className="post-list__post post">
                    {/*  */}
					<figure className="post__preview-img">
						<a href="#"><Link to ="/"><img src={ComingSoon} alt="img description" /></Link></a>
					</figure>
					
					<div className="post__header">
						<time datetime="2019-11-16" className="post__time">N/A</time>
						<h2 className="headingh2"><a href="#"><Link to ="/">Coming Soon</Link></a></h2>
					</div>
					
					<div className="post__meta">
						<a href="#">Soon...</a>
						<a href="#" className="post__comments">0</a>
					</div>
                    {/*  */}
				</div>
            {
                getuserdata.slice(0).reverse().map((element, id) => {
                    const cat = element.category 
                    console.log(cat)
                    
                    return (
                        <>
            <div className="post-list__post post">
                {/*  */}
                <figure className="post__preview-img">
                    <a><Link to ={`/view/${cat}/${element._id}`}><img src={Imag} alt="img description" /></Link></a>
                </figure>
                
                <div className="post__header">
                    <time datetime="2019-11-16" className="post__time">{element.createDate}</time>
                    <h2 className="headingh2"><a><Link to ={`/view/${cat}/${element._id}`}>{element.title}</Link></a></h2>
                </div>
                
                <div className="post__meta">
                    <a><Link to={`${cat}`}>{element.category}</Link></a>
                    <a href="#" className="post__comments">{element.authorname}</a>
                </div>
                {/*  */}
            </div>
            
                        </>
                    )
                })
            }
            <div className="post-list__post post">
                    {/*  */}
					<figure className="post__preview-img">
						<a href="#"><Link to ="/viewstory/lifestory"><img src={LifeImg} alt="img description" /></Link></a>
					</figure>

					
					<div className="post__header">
						<time datetime="2019-11-16" className="post__time">2022-04-24T08:15:52.000Z</time>
						<h2 className="headingh2"><a href="#"><Link to ="/viewstory/lifestory">Life - Motivating Story</Link></a></h2>
					</div>
					
					<div className="post__meta">
						<a><Link to="/englishstories">English Stories</Link></a>
						<a href="#" className="post__comments">Ajay Krishnan P</a>
					</div>
                    {/*  */}
				</div>
                {/*  */}
                <div className="post-list__post post">
                    {/*  */}
					<figure className="post__preview-img">
						<a href="#"><Link to ="/entertainment"><img src={Youtube} alt="img description" /></Link></a>
					</figure>

					
					<div className="post__header">
						<time datetime="2019-11-16" className="post__time">2022-04-24T08:15:52.000Z</time>
						<h2 className="headingh2"><a href="#"><Link to ="/entertainment">Entertainment</Link></a></h2>
					</div>
					
					<div className="post__meta">
						<a><Link to="/entertainment">Entertainment</Link></a>
						<a href="#" className="post__comments"></a>
					</div>
                    {/*  */}
				</div>
            
            </div>
			
			
            </div>
        </>
    )
}

export default EnglishStories;