
// import './App.css';
import LoadingScreen from './Components/LoadingScreen';
// import TopHeader from './Components/TopHeader';
import './darkmode.scss'
// import Navigation from './Route/Navigation';

function App() {
  return (
    <>
    <LoadingScreen />
    </>
  );
}

export default App;
