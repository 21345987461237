import {useEffect, useState} from 'react';

import './PhotographyNew.css'
import '../../Styles/BlogPosts.css'

import Maintanence from '../../Images/undermaintain.jpeg'

import { Link } from 'react-router-dom';


const PhotographyNew = () => {
    const [getPhotos, setPhotos] = useState([]);
    console.log(getPhotos);

    const getdata = async () => {

        // const res = await fetch("https://google-photos-album-demo2.glitch.me/fPv8aj3G5YmFvtBz7", {
        const res = await fetch("https://akblogs-photos.ajaykrishnan.in/fPv8aj3G5YmFvtBz7", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();
        console.log(data);

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setPhotos(data)
            console.log("get data");

        }
    }

    useEffect(() => {
        getdata();
    }, [])


  return (
      <div className="main">
          <Link to="/photography"><h1 className="headingh1">Photography</h1></Link>
       
        
          <div>
           {/* <h3>When our website's performance turned into a dazzling show, an unexpected glitch stepped onto the stage, reminding us of a little hiccup in the backend.
            We will be soon as soon as possible...
            <br></br>
            ------------AK Blogs------------
           </h3>
           <br></br>
           <div className='photography-container'>
                <img src = {Maintanence} />
           </div> */}
         </div>
          <div class="photography-container">
         {getPhotos.map((image) => (
             <img src={image} />
         ) )}
         </div>

   


    </div>



  );
}

export default PhotographyNew;
