
import React,{Component} from 'react';
import Logo from '../Images/logo.png'
import LogoDark from '../Images/logo-dark.png'

import {Link} from 'react-router-dom';

import '../Styles/TopHeader.css'

import { Code, Label, Switch, Button, Intent, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";


class TopHeader extends Component {
    constructor(){
        super()
        if(localStorage.getItem("DarkMode") === null){
            localStorage.setItem("DarkMode", "no");
        }
        this.state = {
            darkMode:localStorage.getItem("DarkMode") === 'yes',
            activeIndex: 0,

          
        };
        this.switchTriggered = this.switchTriggered.bind(this)    
        this.isOpen = this.isOpen.bind(this)
    }
    switchTriggered(){
        // evt.preventDefault();
        localStorage.setItem("DarkMode", (!this.state.darkMode)?"yes":"no");
            this.setState({darkMode:!this.state.darkMode});
        }
        
        isOpen() {
            this.setState({ activeIndex: !this.state.activeIndex });
        }

    render() {
        document.body.className = ((this.state.activeIndex)?"navigation-menu expanded":"navigation-menu")
        document.body.className = ((this.state.darkMode)?"dark-mode":"body");
            console.log("Darkmode:", ((this.state.darkMode)?"dark-mode":"body"), "state is", this.state.darkMode, "from LS", localStorage.getItem("DarkMode"));
            const dmode = this.state.darkMode
        return (
            <>
            <nav className="navigation">
      <a href="/" className="brand-name">
        <span>
        {
                        dmode ? <img src={LogoDark} width="64px" alt=""  /> : <img src={Logo} width="64px" alt="" />
                    }
        </span>
      </a>
      <div>
      </div>
      <button onClick={this.isOpen} className="hamburger">
        {/* icon from heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      
       
            
         
      <div
        className={
          this.state.activeIndex == 1 ? 'navigation-menu expanded': 'navigation-menu'
        }>
        <ul>
          <li><a ><Link to="/"> Home</Link></a></li>
          {/* <li><a><Link to="/write"> Write</Link></a></li> */}
          {/* <li><a><Link to="/tamilkavithai">தமிழ் கவிதை</Link></a></li>

          <li><a> <p className="topheadpara">தமிழ் கதை</p>
          <Popover
                interactionKind={PopoverInteractionKind.HOVER}
                popoverClassName="bp4-popover-content-sizing"
                position={Position.BOTTOM}
            >
                 <p className="popover-para"> தமிழ் கதை</p>
                <div>
  
                <a><Link to="/tamilkathai/sirukathaigal">சிறுகதைகள்</Link></a> <br></br>
                <a><Link to="/tamilkathai/kannampoochikaathal">கண்ணாம்பூச்சி காதல்</Link></a>
                </div>
            </Popover>
            </a></li>
            <li><a><Link to="/englishpoems">English Poems</Link></a></li>
            <li><a><Link to="/englishstories">English Stories</Link></a></li>
            <li><a><Link to="/technical">Technical</Link></a></li>
            <li><a><Link to="/quotes">Quotes</Link></a></li> */}
            <li>
            <Switch style ={{color: 'white'}} checked={this.state.darkMode} innerLabelChecked="Night Mode" innerLabel="Day Mode" onChange={this.switchTriggered}/>
            </li>


          
        </ul>
      </div>
      <div>
      
      </div>
    </nav>
            
            </>
        )
    }
}


export default TopHeader;